import React, { useState, useEffect } from "react";
import AboutUsSectionSlider from './about-section-slider';
import CenterTextContainer from './center-text-container';

function AboutUsSection () {
    return (
        <div id="about-us-section" className="pt-20 pb-12 bg-zinc-200 relative">
            <CenterTextContainer />
            <AboutUsSectionSlider />
        </div>
    );

}

export default AboutUsSection;