import React from "react";
import Slider from "react-slick";
import "../../assets/css/slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../assets/images/carousel/kutko-gradnja-Slider-1.jpg";
import image2 from "../../assets/images/carousel/kutko-gradnja-Slider-2.jpg";
import image3 from "../../assets/images/carousel/kutko-gradnja-Slider-3.jpg";
import image4 from "../../assets/images/carousel/kutko-gradnja-Slider-4.jpg";
import image5 from "../../assets/images/carousel/kutko-gradnja-Slider-5.jpg";

const images = [image1, image2, image3, image4, image5];


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className="carousel absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={onClick}>
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full zinc-200/20 dark:bg-zinc-200/20 group-hover:bg-zinc-200/50 dark:group-hover:bg-zinc-200/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-red-700/70 group-focus:outline-none">
                <svg className="w-4 h-4 text-white dark:text-black hover:text-red-700 rtl:rotate-180 dark:group-focus:text-red-700/70" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                </svg>
                <span className="sr-only">Next</span>
            </span>
        </button>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <button type="button" className="carousel absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={onClick}>
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full zinc-200/20 dark:bg-zinc-200/20 group-hover:bg-zinc-200/50 dark:group-hover:bg-zinc-200/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-red-700/70 group-focus:outline-none">
                <svg className="w-4 h-4 text-white  dark:text-black hover:text-red-700 rtl:rotate-180 dark:group-focus:text-red-700/70" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
                </svg>
                <span className="sr-only">Previous</span>
            </span>
        </button>
    );
}

function Carousel () {
    var settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 5000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const slides = [
        { title: "We are industry Factory solution", image: image1, description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the." },
        { title: "Explore Our Services", image: image2, description: "Quality you can trust" },
        { title: "Join Our Community", image: image3, description: "Be part of something great" },
        { title: "Join Our Community", image: image4, description: "Be part of something great" },
        { title: "Join Our Community", image: image5, description: "Be part of something great" },
    ];

    return (
        <div id="default-carousel" className="relative w-full" data-carousel="slide">
            <div className="relative h-56 overflow-hidden md:h-screen">
                <div className="slider-container">
                    <Slider {...settings}>
                        {slides.map((slide, index) => (
                            <>
                                <div className="mil-overlay bg-black/50 dark:bg-black/50 backdrop-opacity-10 w-full md:h-screen absolute flex z-30"></div>
                                <img key={index} src={slide.image} alt={`Slide ${index + 1}`} className="absolute h-56 md:h-screen object-cover block w-full"/>
                                <div className="z-30 gap-1 columns-2 relative container mx-auto px-4">
                                    <div className="flex flex-wrap items-center inline-flex h-screen">
                                        <div className="hero-section w-full">
                                            <h2 className="title">{slide.title}</h2>
                                            <div className="carusel-description">
                                                {slide.description}
                                            </div>
                                            <div className="buttons">
                                                <a href="/services-1">Read More</a>
                                                <a href="/about-1">About Us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Carousel;