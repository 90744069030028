import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import CenterTextContainer from './center-text-container';
import "../../assets/css/animation.css";
import "../../assets/css/service-section.css";

const cards = ["Card 1", "Card 2", "Card 3", "Card 4", "Card 5", "Card 6"]; // Example card content
function ServiceSection () {
    const { ref, inView } = useInView({
        threshold: 0.5, // Trigger when 50% of the section is visible
        triggerOnce: true, // Only trigger animation once
    });
    console.log(inView);
    return (
        <div id="service-section" className="pt-20 pb-12 bg-zinc-200 relative" ref={ref}>
            <CenterTextContainer />
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                    {cards.map((card, index) => (
                        <div className={`group ${inView ? "fadeInUp" : "hidden-fadeInUp"}`} style={{
                            animationDelay: `${index * 0.2}s`,
                            animationDuration: `2s`,
                        }}>
                            <div className="flex relative justify-between service-box h-full">
                                <div className="icon-bx-wraper" data-name={index}>
                                    <div className="icon-lg w-[80px] mb-[25px]">
                                        <a href="#" className="icon-cell">
                                            <i className="flaticon-robot-arm"></i>
                                        </a>
                                    </div>
                                    <div className="icon-content">
                                        <h2 className="dlab-title">Mechanical Works</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/*<div className="group relative">*/}
                    {/*    <div className="flex justify-between service-box h-full">*/}
                    {/*        <div className="icon-bx-wraper" data-name="02">*/}
                    {/*            <div className="icon-lg w-[80px] mb-[25px]">*/}
                    {/*                <a href="#" className="icon-cell">*/}
                    {/*                    <i className="flaticon-robot-arm"></i>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            <div className="icon-content">*/}
                    {/*                <h2 className="dlab-title">Mechanical Works</h2>*/}
                    {/*                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="group relative">*/}
                    {/*    <div className="flex justify-between service-box h-full">*/}
                    {/*        <div className="icon-bx-wraper" data-name="03">*/}
                    {/*            <div className="icon-lg w-[80px] mb-[25px]">*/}
                    {/*                <a href="#" className="icon-cell">*/}
                    {/*                    <i className="flaticon-robot-arm"></i>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            <div className="icon-content">*/}
                    {/*                <h2 className="dlab-title">Mechanical Works</h2>*/}
                    {/*                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="group relative">*/}
                    {/*    <div className="flex justify-between service-box h-full">*/}
                    {/*        <div className="icon-bx-wraper" data-name="04">*/}
                    {/*            <div className="icon-lg w-[80px] mb-[25px]">*/}
                    {/*                <a href="#" className="icon-cell">*/}
                    {/*                    <i className="flaticon-robot-arm"></i>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            <div className="icon-content">*/}
                    {/*                <h2 className="dlab-title">Mechanical Works</h2>*/}
                    {/*                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="group relative">*/}
                    {/*    <div className="flex justify-between service-box h-full">*/}
                    {/*        <div className="icon-bx-wraper" data-name="05">*/}
                    {/*            <div className="icon-lg w-[80px] mb-[25px]">*/}
                    {/*                <a href="#" className="icon-cell">*/}
                    {/*                    <i className="flaticon-robot-arm"></i>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            <div className="icon-content">*/}
                    {/*                <h2 className="dlab-title">Mechanical Works</h2>*/}
                    {/*                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="group relative">*/}
                    {/*    <div className="flex justify-between service-box h-full">*/}
                    {/*        <div className="icon-bx-wraper" data-name="06">*/}
                    {/*            <div className="icon-lg w-[80px] mb-[25px]">*/}
                    {/*                <a href="#" className="icon-cell">*/}
                    {/*                    <i className="flaticon-robot-arm"></i>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            <div className="icon-content">*/}
                    {/*                <h2 className="dlab-title">Mechanical Works</h2>*/}
                    {/*                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );

}

export default ServiceSection;