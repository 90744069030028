import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Map from './pages/Map';
import Contact from './pages/Contact';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


// const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    { path: '/', element: <App /> },
    { path: '/map', element: <Map /> },
    { path: '/contact', element: <Contact /> },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)

// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// );



// reportWebVitals();

