import React, { useState, useEffect } from "react";
import logo from '../logo.svg';
import '../App.css';
import Header from '../components/ui/header';
import Footer from '../components/ui/footer';

import HomePage from './Home'
import '../assets/css/main.css';
import '../assets/fonts/poppins/Poppins-Regular.ttf';

function ContactPage() {
    return (
        <>
            <Header />

            <Footer />
        </>
    );
}

export default ContactPage;
