import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../../App';
import Map from '../../pages/Map';
import Loading from './loading';
import "../../assets/css/header/header.css";
import logo from "../../assets/images/logo/kutko-logo-stari-dark-small.png";

function Header () {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [mobileIsOpen, setMobileIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch('https://api.mvdesign.rs/');
    //             const result = await response.json();
    //             setData(result);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         } finally {
    //             setLoading(false);  // Set loading to false after data is fetched
    //         }
    //     };
    //
    //     fetchData();
    //
    // }, []);

    const toggleCollapse = () => {
        setIsOpen((prev) => !prev);
    };

    const toggleMobileMenu = () => {
        setMobileIsOpen((prev) => !prev);
    };

    React.useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset > 40) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
    });

    return (
        <nav className={`fixed w-full z-50 bg-zinc-200/50 border-gray-600 dark:border-gray-600 ${scrolled ? 'dark:bg-zinc-200' : 'dark:bg-zinc-200/50'}`}>
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                <a href="https://flowbite.com" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={logo} alt='Kutko' className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"/>
                </a>
                <button onClick={toggleMobileMenu} data-collapse-toggle={mobileIsOpen} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-black dark:hover:bg-zinc-200 dark:focus:ring-red-700" aria-controls="mega-menu-full-image" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
                <div id="mega-menu-full-image" className={`items-center justify-between ${mobileIsOpen ? 'show' : 'hidden'} w-full md:flex md:w-auto md:order-1`}>
                    <ul className="flex flex-col uppercase mt-4 font-medium md:flex-row md:mt-0 rtl:space-x-reverse">
                        <li>
                            <a href="/" className="block link link--metis relative link-custom-border py-2 px-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-black md:dark:hover:text-red-700 dark:hover:bg-gray-700 dark:hover:text-red-700 md:dark:hover:bg-transparent dark:border-gray-700" aria-current="page">Pocetna</a>
                        </li>
                        <li>
                            <button id="mega-menu-full-cta-image-button " onClick={toggleCollapse} data-collapse-toggle={isOpen} className="flex link link--metis relative link-custom-border items-center justify-between w-full py-2 px-3 font-medium text-black border-b border-red-700 md:w-auto hover:text-red-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0 dark:text-black md:dark:hover:text-red-700 dark:hover:text-red-700 dark:hover:text-red-700 md:dark:hover:bg-transparent dark:border-gray-700 uppercase">
                                Lokacije
                                {!isOpen &&<svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                                </svg>}
                                {isOpen &&<svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"></path>
                                </svg>}
                            </button>
                        </li>
                        <li>
                            <a href="/map" className="block link link--metis relative link-custom-border py-2 px-3 text-black border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-black md:dark:hover:text-red-700 dark:hover:bg-gray-700 dark:hover:text-red-700 md:dark:hover:bg-transparent dark:border-gray-700">Mapa Lokacija</a>
                        </li>
                        <li>
                            <a href="/about" className="block link link--metis relative link-custom-border py-2 px-3 text-black border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-black md:dark:hover:text-red-700 dark:hover:bg-gray-700 dark:hover:text-red-700 md:dark:hover:bg-transparent dark:border-gray-700">O nama</a>
                        </li>
                        <li>
                            <a href="/contact" className="block link link--metis relative link-custom-border py-2 px-3 text-black border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-black md:dark:hover:text-red-700 dark:hover:bg-gray-700 dark:hover:text-red-700 md:dark:hover:bg-transparent dark:border-gray-700">Kontakt</a>
                        </li>
                        <li>
                            {/*<Route path="/Map" element={<Map />} className="block link link--metis relative link-custom-border py-2 px-3 text-black border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-black md:dark:hover:text-red-700 dark:hover:bg-gray-700 dark:hover:text-red-700 md:dark:hover:bg-transparent dark:border-gray-700" />*/}
                        </li>

                    </ul>
                </div>
            </div>
            {isOpen && <div id="mega-menu-full-image-dropdown" className="border-gray-600 shadow-sm border-y dark:border-gray-600">
                <div className="grid max-w-screen-xl px-4 py-5 mx-auto text-sm text-gray-500 dark:text-gray-400 md:grid-cols-3 md:px-6">
                    <ul className="hidden mb-4 space-y-4 md:mb-0 md:block" aria-labelledby="mega-menu-full-image-button">
                        <li>
                            <a href="#" className="text-black dark:text-black hover:text-red-700 dark:hover:text-red-700">
                                Online Stores
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-black dark:text-black hover:text-red-700 dark:hover:text-red-700">
                                Segmentation
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-black dark:text-black hover:text-red-700 dark:hover:text-red-700">
                                Marketing CRM
                            </a>
                        </li>
                        <li>
                            {/*<a href="#" className="text-black dark:text-black hover:text-red-700 dark:hover:text-red-700">*/}
                            {/*    Online Stores*/}
                            {/*</a>*/}
                        </li>
                    </ul>
                    <ul className="mb-4 space-y-4 md:mb-0">
                        <li>
                            <a href="#" className="text-black dark:text-black hover:text-red-700 dark:hover:text-red-700">
                                Our Blog
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-black dark:text-black hover:text-red-700 dark:hover:text-red-700">
                                Terms & Conditions
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-black dark:text-black hover:text-red-700 dark:hover:text-red-700">
                                License
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-black dark:text-black hover:text-red-700 dark:hover:text-red-700">
                                Resources
                            </a>
                        </li>
                    </ul>
                    <a href="#" className="p-8 bg-local bg-gray-500 bg-center bg-no-repeat bg-cover rounded-lg bg-blend-multiply hover:bg-blend-soft-light dark:hover:bg-blend-darken">
                        <p className="max-w-xl mb-5 font-extrabold leading-tight tracking-tight text-white">Preview the new Flowbite dashboard navigation.</p>
                        <button type="button" className="inline-flex items-center px-2.5 py-1.5 text-xs font-medium text-center text-white border border-white rounded-lg hover:bg-white hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-700">Get started</button>
                    </a>
                </div>
            </div>}
        </nav>

    );
}

export default Header;