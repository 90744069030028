import React, { useState, useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import Header from './components/ui/header';
import Carousel from './components/ui/carousel';
import AboutUsSection from './components/ui/aboutus-section';
import ServiceSection from './components/ui/service-section';
import Footer from './components/ui/footer';
import Loading from './components/ui/loading';

import './assets/css/main.css';
import './assets/fonts/poppins/Poppins-Regular.ttf';

function App() {

  const [loading, setLoading] = useState(true);

  document.body.classList.add('font-kutko-regular','overflow-hidden');
  document.addEventListener('readystatechange', function() {
    if (document.readyState === 'complete') {
      setLoading(false);
    }
  });
  if(!loading){
    document.body.classList.remove('overflow-hidden');
  }

  return (
      <>
        {/*{loading ? (*/}
        {/*    <Loading />*/}
        {/*) : (*/}
        {/*    <>*/}
              <Header />
              <Carousel />
              <AboutUsSection />
              <ServiceSection />
              <Footer />
            {/*</>*/}
        {/*)}*/}
    </>
  );
}

export default App;
